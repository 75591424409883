/* Auto generated by schemaGen.ts */
import type * as dbt from './db'
import type * as dbtInsert from './dbInsert'

export interface DBTables {
  addons: dbt.Addons
  appQualityConfig: dbt.AppQualityConfig
  appQualityConfigSchemas: dbt.AppQualityConfigSchemas
  assetsToDelete: dbt.AssetsToDelete
  auditAddons: dbt.AuditAddons
  auditCustomers: dbt.AuditCustomers
  auditOrganizations: dbt.AuditOrganizations
  auditPlans: dbt.AuditPlans
  auditSubscriptionAddons: dbt.AuditSubscriptionAddons
  auditSubscriptions: dbt.AuditSubscriptions
  bigintCloudAssets: dbt.BigintCloudAssets
  bigintInstanceDetails: dbt.BigintInstanceDetails
  bigintInstanceStdout: dbt.BigintInstanceStdout
  bigintInstances: dbt.BigintInstances
  bigintProtocolInstanceRaw: dbt.BigintProtocolInstanceRaw
  bigintProtocolTestData: dbt.BigintProtocolTestData
  bigintScreencasts: dbt.BigintScreencasts
  bigintScreenshots: dbt.BigintScreenshots
  bigintTestResultAnalytics: dbt.BigintTestResultAnalytics
  bigintTestResultAttemptErrors: dbt.BigintTestResultAttemptErrors
  bigintTestResultAttempts: dbt.BigintTestResultAttempts
  bigintTestResultErrors: dbt.BigintTestResultErrors
  bigintTestResultsNew: dbt.BigintTestResultsNew
  bitbucketAppInstallOrganization: dbt.BitbucketAppInstallOrganization
  bitbucketAppPullRequestComment: dbt.BitbucketAppPullRequestComment
  bitbucketAppRepositoryProject: dbt.BitbucketAppRepositoryProject
  buildSettings: dbt.BuildSettings
  builds: dbt.Builds
  buildsTags: dbt.BuildsTags
  captureProtocolScripts: dbt.CaptureProtocolScripts
  captureProtocolScriptsLiveVersions: dbt.CaptureProtocolScriptsLiveVersions
  ciKeys: dbt.CiKeys
  ciProviders: dbt.CiProviders
  clickhouseFailedInsert: dbt.ClickhouseFailedInsert
  cloudAssets: dbt.CloudAssets
  customers: dbt.Customers
  deletedProjects: dbt.DeletedProjects
  deletions: dbt.Deletions
  deletionsArchive: dbt.DeletionsArchive
  discoveryCompactionInstanceResults: dbt.DiscoveryCompactionInstanceResults
  discoveryCompactionRunResults: dbt.DiscoveryCompactionRunResults
  enterpriseReporting: dbt.EnterpriseReporting
  featureSettings: dbt.FeatureSettings
  githubAppInstall: dbt.GithubAppInstall
  githubAppInstallEnterprise: dbt.GithubAppInstallEnterprise
  githubAppInstallOrganization: dbt.GithubAppInstallOrganization
  githubAppPullRequestComment: dbt.GithubAppPullRequestComment
  githubAppRepository: dbt.GithubAppRepository
  githubAppRepositoryOwner: dbt.GithubAppRepositoryOwner
  githubAppStatusCheck: dbt.GithubAppStatusCheck
  githubAppWebhookEvents: dbt.GithubAppWebhookEvents
  gitlabAppInstallOrganization: dbt.GitlabAppInstallOrganization
  gitlabAppMergeRequestComment: dbt.GitlabAppMergeRequestComment
  gitlabAppRepository: dbt.GitlabAppRepository
  identityProviderConfig: dbt.IdentityProviderConfig
  identityProviderDomainMappings: dbt.IdentityProviderDomainMappings
  instanceActions: dbt.InstanceActions
  instanceDetails: dbt.InstanceDetails
  instanceSpecHashes: dbt.InstanceSpecHashes
  instanceStdout: dbt.InstanceStdout
  instances: dbt.Instances
  integrations: dbt.Integrations
  jiraAppCreatedIssues: dbt.JiraAppCreatedIssues
  jiraAppInstallOrganization: dbt.JiraAppInstallOrganization
  jiraAppProjects: dbt.JiraAppProjects
  jobResponsibilities: dbt.JobResponsibilities
  machines: dbt.Machines
  membershipInvitationLinks: dbt.MembershipInvitationLinks
  membershipInvitations: dbt.MembershipInvitations
  membershipRequests: dbt.MembershipRequests
  memberships: dbt.Memberships
  microsoftTeamsAppInstalls: dbt.MicrosoftTeamsAppInstalls
  nextEstimatedSpecDurations: dbt.NextEstimatedSpecDurations
  organizationOnboardingChecklist: dbt.OrganizationOnboardingChecklist
  organizations: dbt.Organizations
  ossPlanRequests: dbt.OssPlanRequests
  outgoingEmails: dbt.OutgoingEmails
  plans: dbt.Plans
  projectBranchBuilds: dbt.ProjectBranchBuilds
  projectBranches: dbt.ProjectBranches
  projectCiProviders: dbt.ProjectCiProviders
  projectCommitterBuilds: dbt.ProjectCommitterBuilds
  projectCommitters: dbt.ProjectCommitters
  projectGithubIntegration: dbt.ProjectGithubIntegration
  projectGitlabIntegration: dbt.ProjectGitlabIntegration
  projectIntelligenceConfig: dbt.ProjectIntelligenceConfig
  projectTransfers: dbt.ProjectTransfers
  projects: dbt.Projects
  projectsToDelete: dbt.ProjectsToDelete
  protocolHashData: dbt.ProtocolHashData
  protocolInstanceRaw: dbt.ProtocolInstanceRaw
  protocolTestData: dbt.ProtocolTestData
  runCompositeConfig: dbt.RunCompositeConfig
  runGroups: dbt.RunGroups
  runGroupsBackfill: dbt.RunGroupsBackfill
  s3UploadErrors: dbt.S3UploadErrors
  scheduledTasks: dbt.ScheduledTasks
  screencasts: dbt.Screencasts
  screenshots: dbt.Screenshots
  session: dbt.Session
  slackAppInstallOverrides: dbt.SlackAppInstallOverrides
  slackAppInstallTagOverrides: dbt.SlackAppInstallTagOverrides
  slackAppInstalls: dbt.SlackAppInstalls
  slackAppTokens: dbt.SlackAppTokens
  sorryCypressLimiter: dbt.SorryCypressLimiter
  subscriptionAddons: dbt.SubscriptionAddons
  subscriptions: dbt.Subscriptions
  tags: dbt.Tags
  termsAcceptances: dbt.TermsAcceptances
  testAttribution: dbt.TestAttribution
  testAttributionUnique: dbt.TestAttributionUnique
  testOrchestration: dbt.TestOrchestration
  testResultActions: dbt.TestResultActions
  testResultAnalytics: dbt.TestResultAnalytics
  testResultAttemptErrors: dbt.TestResultAttemptErrors
  testResultAttempts: dbt.TestResultAttempts
  testResultBodyHashes: dbt.TestResultBodyHashes
  testResultConfigHashes: dbt.TestResultConfigHashes
  testResultErrors: dbt.TestResultErrors
  testResultHooksHashes: dbt.TestResultHooksHashes
  testResultScoreTrackerByBranch: dbt.TestResultScoreTrackerByBranch
  testResultTitleHashes: dbt.TestResultTitleHashes
  testResultsNew: dbt.TestResultsNew
  usageLimitedBuilds: dbt.UsageLimitedBuilds
  userAuth: dbt.UserAuth
  userEmails: dbt.UserEmails
  userJobResponsibilities: dbt.UserJobResponsibilities
  userKeys: dbt.UserKeys
  userLoginErrors: dbt.UserLoginErrors
  userLogins: dbt.UserLogins
  userProfilesGithub: dbt.UserProfilesGithub
  userProfilesGoogle: dbt.UserProfilesGoogle
  users: dbt.Users
  usersManualDeletions: dbt.UsersManualDeletions
}

export interface DBTablesInsert {
  addons: dbtInsert.Addons
  appQualityConfig: dbtInsert.AppQualityConfig
  appQualityConfigSchemas: dbtInsert.AppQualityConfigSchemas
  assetsToDelete: dbtInsert.AssetsToDelete
  auditAddons: dbtInsert.AuditAddons
  auditCustomers: dbtInsert.AuditCustomers
  auditOrganizations: dbtInsert.AuditOrganizations
  auditPlans: dbtInsert.AuditPlans
  auditSubscriptionAddons: dbtInsert.AuditSubscriptionAddons
  auditSubscriptions: dbtInsert.AuditSubscriptions
  bigintCloudAssets: dbtInsert.BigintCloudAssets
  bigintInstanceDetails: dbtInsert.BigintInstanceDetails
  bigintInstanceStdout: dbtInsert.BigintInstanceStdout
  bigintInstances: dbtInsert.BigintInstances
  bigintProtocolInstanceRaw: dbtInsert.BigintProtocolInstanceRaw
  bigintProtocolTestData: dbtInsert.BigintProtocolTestData
  bigintScreencasts: dbtInsert.BigintScreencasts
  bigintScreenshots: dbtInsert.BigintScreenshots
  bigintTestResultAnalytics: dbtInsert.BigintTestResultAnalytics
  bigintTestResultAttemptErrors: dbtInsert.BigintTestResultAttemptErrors
  bigintTestResultAttempts: dbtInsert.BigintTestResultAttempts
  bigintTestResultErrors: dbtInsert.BigintTestResultErrors
  bigintTestResultsNew: dbtInsert.BigintTestResultsNew
  bitbucketAppInstallOrganization: dbtInsert.BitbucketAppInstallOrganization
  bitbucketAppPullRequestComment: dbtInsert.BitbucketAppPullRequestComment
  bitbucketAppRepositoryProject: dbtInsert.BitbucketAppRepositoryProject
  buildSettings: dbtInsert.BuildSettings
  builds: dbtInsert.Builds
  buildsTags: dbtInsert.BuildsTags
  captureProtocolScripts: dbtInsert.CaptureProtocolScripts
  captureProtocolScriptsLiveVersions: dbtInsert.CaptureProtocolScriptsLiveVersions
  ciKeys: dbtInsert.CiKeys
  ciProviders: dbtInsert.CiProviders
  clickhouseFailedInsert: dbtInsert.ClickhouseFailedInsert
  cloudAssets: dbtInsert.CloudAssets
  customers: dbtInsert.Customers
  deletedProjects: dbtInsert.DeletedProjects
  deletions: dbtInsert.Deletions
  deletionsArchive: dbtInsert.DeletionsArchive
  discoveryCompactionInstanceResults: dbtInsert.DiscoveryCompactionInstanceResults
  discoveryCompactionRunResults: dbtInsert.DiscoveryCompactionRunResults
  enterpriseReporting: dbtInsert.EnterpriseReporting
  featureSettings: dbtInsert.FeatureSettings
  githubAppInstall: dbtInsert.GithubAppInstall
  githubAppInstallEnterprise: dbtInsert.GithubAppInstallEnterprise
  githubAppInstallOrganization: dbtInsert.GithubAppInstallOrganization
  githubAppPullRequestComment: dbtInsert.GithubAppPullRequestComment
  githubAppRepository: dbtInsert.GithubAppRepository
  githubAppRepositoryOwner: dbtInsert.GithubAppRepositoryOwner
  githubAppStatusCheck: dbtInsert.GithubAppStatusCheck
  githubAppWebhookEvents: dbtInsert.GithubAppWebhookEvents
  gitlabAppInstallOrganization: dbtInsert.GitlabAppInstallOrganization
  gitlabAppMergeRequestComment: dbtInsert.GitlabAppMergeRequestComment
  gitlabAppRepository: dbtInsert.GitlabAppRepository
  identityProviderConfig: dbtInsert.IdentityProviderConfig
  identityProviderDomainMappings: dbtInsert.IdentityProviderDomainMappings
  instanceActions: dbtInsert.InstanceActions
  instanceDetails: dbtInsert.InstanceDetails
  instanceSpecHashes: dbtInsert.InstanceSpecHashes
  instanceStdout: dbtInsert.InstanceStdout
  instances: dbtInsert.Instances
  integrations: dbtInsert.Integrations
  jiraAppCreatedIssues: dbtInsert.JiraAppCreatedIssues
  jiraAppInstallOrganization: dbtInsert.JiraAppInstallOrganization
  jiraAppProjects: dbtInsert.JiraAppProjects
  jobResponsibilities: dbtInsert.JobResponsibilities
  machines: dbtInsert.Machines
  membershipInvitationLinks: dbtInsert.MembershipInvitationLinks
  membershipInvitations: dbtInsert.MembershipInvitations
  membershipRequests: dbtInsert.MembershipRequests
  memberships: dbtInsert.Memberships
  microsoftTeamsAppInstalls: dbtInsert.MicrosoftTeamsAppInstalls
  nextEstimatedSpecDurations: dbtInsert.NextEstimatedSpecDurations
  organizationOnboardingChecklist: dbtInsert.OrganizationOnboardingChecklist
  organizations: dbtInsert.Organizations
  ossPlanRequests: dbtInsert.OssPlanRequests
  outgoingEmails: dbtInsert.OutgoingEmails
  plans: dbtInsert.Plans
  projectBranchBuilds: dbtInsert.ProjectBranchBuilds
  projectBranches: dbtInsert.ProjectBranches
  projectCiProviders: dbtInsert.ProjectCiProviders
  projectCommitterBuilds: dbtInsert.ProjectCommitterBuilds
  projectCommitters: dbtInsert.ProjectCommitters
  projectGithubIntegration: dbtInsert.ProjectGithubIntegration
  projectGitlabIntegration: dbtInsert.ProjectGitlabIntegration
  projectIntelligenceConfig: dbtInsert.ProjectIntelligenceConfig
  projectTransfers: dbtInsert.ProjectTransfers
  projects: dbtInsert.Projects
  projectsToDelete: dbtInsert.ProjectsToDelete
  protocolHashData: dbtInsert.ProtocolHashData
  protocolInstanceRaw: dbtInsert.ProtocolInstanceRaw
  protocolTestData: dbtInsert.ProtocolTestData
  runCompositeConfig: dbtInsert.RunCompositeConfig
  runGroups: dbtInsert.RunGroups
  runGroupsBackfill: dbtInsert.RunGroupsBackfill
  s3UploadErrors: dbtInsert.S3UploadErrors
  scheduledTasks: dbtInsert.ScheduledTasks
  screencasts: dbtInsert.Screencasts
  screenshots: dbtInsert.Screenshots
  session: dbtInsert.Session
  slackAppInstallOverrides: dbtInsert.SlackAppInstallOverrides
  slackAppInstallTagOverrides: dbtInsert.SlackAppInstallTagOverrides
  slackAppInstalls: dbtInsert.SlackAppInstalls
  slackAppTokens: dbtInsert.SlackAppTokens
  sorryCypressLimiter: dbtInsert.SorryCypressLimiter
  subscriptionAddons: dbtInsert.SubscriptionAddons
  subscriptions: dbtInsert.Subscriptions
  tags: dbtInsert.Tags
  termsAcceptances: dbtInsert.TermsAcceptances
  testAttribution: dbtInsert.TestAttribution
  testAttributionUnique: dbtInsert.TestAttributionUnique
  testOrchestration: dbtInsert.TestOrchestration
  testResultActions: dbtInsert.TestResultActions
  testResultAnalytics: dbtInsert.TestResultAnalytics
  testResultAttemptErrors: dbtInsert.TestResultAttemptErrors
  testResultAttempts: dbtInsert.TestResultAttempts
  testResultBodyHashes: dbtInsert.TestResultBodyHashes
  testResultConfigHashes: dbtInsert.TestResultConfigHashes
  testResultErrors: dbtInsert.TestResultErrors
  testResultHooksHashes: dbtInsert.TestResultHooksHashes
  testResultScoreTrackerByBranch: dbtInsert.TestResultScoreTrackerByBranch
  testResultTitleHashes: dbtInsert.TestResultTitleHashes
  testResultsNew: dbtInsert.TestResultsNew
  usageLimitedBuilds: dbtInsert.UsageLimitedBuilds
  userAuth: dbtInsert.UserAuth
  userEmails: dbtInsert.UserEmails
  userJobResponsibilities: dbtInsert.UserJobResponsibilities
  userKeys: dbtInsert.UserKeys
  userLoginErrors: dbtInsert.UserLoginErrors
  userLogins: dbtInsert.UserLogins
  userProfilesGithub: dbtInsert.UserProfilesGithub
  userProfilesGoogle: dbtInsert.UserProfilesGoogle
  users: dbtInsert.Users
  usersManualDeletions: dbtInsert.UsersManualDeletions
}

export type DBTableName = Extract<keyof DBTables, string>

export const DBTableNames = [
  'addons',
  'appQualityConfig',
  'appQualityConfigSchemas',
  'assetsToDelete',
  'auditAddons',
  'auditCustomers',
  'auditOrganizations',
  'auditPlans',
  'auditSubscriptionAddons',
  'auditSubscriptions',
  'bigintCloudAssets',
  'bigintInstanceDetails',
  'bigintInstanceStdout',
  'bigintInstances',
  'bigintProtocolInstanceRaw',
  'bigintProtocolTestData',
  'bigintScreencasts',
  'bigintScreenshots',
  'bigintTestResultAnalytics',
  'bigintTestResultAttemptErrors',
  'bigintTestResultAttempts',
  'bigintTestResultErrors',
  'bigintTestResultsNew',
  'bitbucketAppInstallOrganization',
  'bitbucketAppPullRequestComment',
  'bitbucketAppRepositoryProject',
  'buildSettings',
  'builds',
  'buildsTags',
  'captureProtocolScripts',
  'captureProtocolScriptsLiveVersions',
  'ciKeys',
  'ciProviders',
  'clickhouseFailedInsert',
  'cloudAssets',
  'customers',
  'deletedProjects',
  'deletions',
  'deletionsArchive',
  'discoveryCompactionInstanceResults',
  'discoveryCompactionRunResults',
  'enterpriseReporting',
  'featureSettings',
  'githubAppInstall',
  'githubAppInstallEnterprise',
  'githubAppInstallOrganization',
  'githubAppPullRequestComment',
  'githubAppRepository',
  'githubAppRepositoryOwner',
  'githubAppStatusCheck',
  'githubAppWebhookEvents',
  'gitlabAppInstallOrganization',
  'gitlabAppMergeRequestComment',
  'gitlabAppRepository',
  'identityProviderConfig',
  'identityProviderDomainMappings',
  'instanceActions',
  'instanceDetails',
  'instanceSpecHashes',
  'instanceStdout',
  'instances',
  'integrations',
  'jiraAppCreatedIssues',
  'jiraAppInstallOrganization',
  'jiraAppProjects',
  'jobResponsibilities',
  'machines',
  'membershipInvitationLinks',
  'membershipInvitations',
  'membershipRequests',
  'memberships',
  'microsoftTeamsAppInstalls',
  'nextEstimatedSpecDurations',
  'organizationOnboardingChecklist',
  'organizations',
  'ossPlanRequests',
  'outgoingEmails',
  'plans',
  'projectBranchBuilds',
  'projectBranches',
  'projectCiProviders',
  'projectCommitterBuilds',
  'projectCommitters',
  'projectGithubIntegration',
  'projectGitlabIntegration',
  'projectIntelligenceConfig',
  'projectTransfers',
  'projects',
  'projectsToDelete',
  'protocolHashData',
  'protocolInstanceRaw',
  'protocolTestData',
  'runCompositeConfig',
  'runGroups',
  'runGroupsBackfill',
  's3UploadErrors',
  'scheduledTasks',
  'screencasts',
  'screenshots',
  'session',
  'slackAppInstallOverrides',
  'slackAppInstallTagOverrides',
  'slackAppInstalls',
  'slackAppTokens',
  'sorryCypressLimiter',
  'subscriptionAddons',
  'subscriptions',
  'tags',
  'termsAcceptances',
  'testAttribution',
  'testAttributionUnique',
  'testOrchestration',
  'testResultActions',
  'testResultAnalytics',
  'testResultAttemptErrors',
  'testResultAttempts',
  'testResultBodyHashes',
  'testResultConfigHashes',
  'testResultErrors',
  'testResultHooksHashes',
  'testResultScoreTrackerByBranch',
  'testResultTitleHashes',
  'testResultsNew',
  'usageLimitedBuilds',
  'userAuth',
  'userEmails',
  'userJobResponsibilities',
  'userKeys',
  'userLoginErrors',
  'userLogins',
  'userProfilesGithub',
  'userProfilesGoogle',
  'users',
  'usersManualDeletions',
] as const
