/* Auto generated by schemaGen.ts */
import type * as dbtSnake from './db-snake.gen'

export interface DBTablesSnake {
  addons: dbtSnake.addons
  app_quality_config: dbtSnake.app_quality_config
  app_quality_config_schemas: dbtSnake.app_quality_config_schemas
  assets_to_delete: dbtSnake.assets_to_delete
  audit_addons: dbtSnake.audit_addons
  audit_customers: dbtSnake.audit_customers
  audit_organizations: dbtSnake.audit_organizations
  audit_plans: dbtSnake.audit_plans
  audit_subscription_addons: dbtSnake.audit_subscription_addons
  audit_subscriptions: dbtSnake.audit_subscriptions
  bigint_cloud_assets: dbtSnake.bigint_cloud_assets
  bigint_instance_details: dbtSnake.bigint_instance_details
  bigint_instance_stdout: dbtSnake.bigint_instance_stdout
  bigint_instances: dbtSnake.bigint_instances
  bigint_protocol_instance_raw: dbtSnake.bigint_protocol_instance_raw
  bigint_protocol_test_data: dbtSnake.bigint_protocol_test_data
  bigint_screencasts: dbtSnake.bigint_screencasts
  bigint_screenshots: dbtSnake.bigint_screenshots
  bigint_test_result_analytics: dbtSnake.bigint_test_result_analytics
  bigint_test_result_attempt_errors: dbtSnake.bigint_test_result_attempt_errors
  bigint_test_result_attempts: dbtSnake.bigint_test_result_attempts
  bigint_test_result_errors: dbtSnake.bigint_test_result_errors
  bigint_test_results_new: dbtSnake.bigint_test_results_new
  bitbucket_app_install_organization: dbtSnake.bitbucket_app_install_organization
  bitbucket_app_pull_request_comment: dbtSnake.bitbucket_app_pull_request_comment
  bitbucket_app_repository_project: dbtSnake.bitbucket_app_repository_project
  build_settings: dbtSnake.build_settings
  builds: dbtSnake.builds
  builds_tags: dbtSnake.builds_tags
  capture_protocol_scripts: dbtSnake.capture_protocol_scripts
  capture_protocol_scripts_live_versions: dbtSnake.capture_protocol_scripts_live_versions
  ci_keys: dbtSnake.ci_keys
  ci_providers: dbtSnake.ci_providers
  clickhouse_failed_insert: dbtSnake.clickhouse_failed_insert
  cloud_assets: dbtSnake.cloud_assets
  customers: dbtSnake.customers
  deleted_projects: dbtSnake.deleted_projects
  deletions: dbtSnake.deletions
  deletions_archive: dbtSnake.deletions_archive
  discovery_compaction_instance_results: dbtSnake.discovery_compaction_instance_results
  discovery_compaction_run_results: dbtSnake.discovery_compaction_run_results
  enterprise_reporting: dbtSnake.enterprise_reporting
  feature_settings: dbtSnake.feature_settings
  github_app_install: dbtSnake.github_app_install
  github_app_install_enterprise: dbtSnake.github_app_install_enterprise
  github_app_install_organization: dbtSnake.github_app_install_organization
  github_app_pull_request_comment: dbtSnake.github_app_pull_request_comment
  github_app_repository: dbtSnake.github_app_repository
  github_app_repository_owner: dbtSnake.github_app_repository_owner
  github_app_status_check: dbtSnake.github_app_status_check
  github_app_webhook_events: dbtSnake.github_app_webhook_events
  gitlab_app_install_organization: dbtSnake.gitlab_app_install_organization
  gitlab_app_merge_request_comment: dbtSnake.gitlab_app_merge_request_comment
  gitlab_app_repository: dbtSnake.gitlab_app_repository
  identity_provider_config: dbtSnake.identity_provider_config
  identity_provider_domain_mappings: dbtSnake.identity_provider_domain_mappings
  instance_actions: dbtSnake.instance_actions
  instance_details: dbtSnake.instance_details
  instance_spec_hashes: dbtSnake.instance_spec_hashes
  instance_stdout: dbtSnake.instance_stdout
  instances: dbtSnake.instances
  integrations: dbtSnake.integrations
  jira_app_created_issues: dbtSnake.jira_app_created_issues
  jira_app_install_organization: dbtSnake.jira_app_install_organization
  jira_app_projects: dbtSnake.jira_app_projects
  job_responsibilities: dbtSnake.job_responsibilities
  machines: dbtSnake.machines
  membership_invitation_links: dbtSnake.membership_invitation_links
  membership_invitations: dbtSnake.membership_invitations
  membership_requests: dbtSnake.membership_requests
  memberships: dbtSnake.memberships
  microsoft_teams_app_installs: dbtSnake.microsoft_teams_app_installs
  next_estimated_spec_durations: dbtSnake.next_estimated_spec_durations
  organization_onboarding_checklist: dbtSnake.organization_onboarding_checklist
  organizations: dbtSnake.organizations
  oss_plan_requests: dbtSnake.oss_plan_requests
  outgoing_emails: dbtSnake.outgoing_emails
  plans: dbtSnake.plans
  project_branch_builds: dbtSnake.project_branch_builds
  project_branches: dbtSnake.project_branches
  project_ci_providers: dbtSnake.project_ci_providers
  project_committer_builds: dbtSnake.project_committer_builds
  project_committers: dbtSnake.project_committers
  project_github_integration: dbtSnake.project_github_integration
  project_gitlab_integration: dbtSnake.project_gitlab_integration
  project_intelligence_config: dbtSnake.project_intelligence_config
  project_transfers: dbtSnake.project_transfers
  projects: dbtSnake.projects
  projects_to_delete: dbtSnake.projects_to_delete
  protocol_hash_data: dbtSnake.protocol_hash_data
  protocol_instance_raw: dbtSnake.protocol_instance_raw
  protocol_test_data: dbtSnake.protocol_test_data
  run_composite_config: dbtSnake.run_composite_config
  run_groups: dbtSnake.run_groups
  run_groups_backfill: dbtSnake.run_groups_backfill
  s3_upload_errors: dbtSnake.s3_upload_errors
  scheduled_tasks: dbtSnake.scheduled_tasks
  screencasts: dbtSnake.screencasts
  screenshots: dbtSnake.screenshots
  session: dbtSnake.session
  slack_app_install_overrides: dbtSnake.slack_app_install_overrides
  slack_app_install_tag_overrides: dbtSnake.slack_app_install_tag_overrides
  slack_app_installs: dbtSnake.slack_app_installs
  slack_app_tokens: dbtSnake.slack_app_tokens
  sorry_cypress_limiter: dbtSnake.sorry_cypress_limiter
  subscription_addons: dbtSnake.subscription_addons
  subscriptions: dbtSnake.subscriptions
  tags: dbtSnake.tags
  terms_acceptances: dbtSnake.terms_acceptances
  test_attribution: dbtSnake.test_attribution
  test_attribution_unique: dbtSnake.test_attribution_unique
  test_orchestration: dbtSnake.test_orchestration
  test_result_actions: dbtSnake.test_result_actions
  test_result_analytics: dbtSnake.test_result_analytics
  test_result_attempt_errors: dbtSnake.test_result_attempt_errors
  test_result_attempts: dbtSnake.test_result_attempts
  test_result_body_hashes: dbtSnake.test_result_body_hashes
  test_result_config_hashes: dbtSnake.test_result_config_hashes
  test_result_errors: dbtSnake.test_result_errors
  test_result_hooks_hashes: dbtSnake.test_result_hooks_hashes
  test_result_score_tracker_by_branch: dbtSnake.test_result_score_tracker_by_branch
  test_result_title_hashes: dbtSnake.test_result_title_hashes
  test_results_new: dbtSnake.test_results_new
  usage_limited_builds: dbtSnake.usage_limited_builds
  user_auth: dbtSnake.user_auth
  user_emails: dbtSnake.user_emails
  user_job_responsibilities: dbtSnake.user_job_responsibilities
  user_keys: dbtSnake.user_keys
  user_login_errors: dbtSnake.user_login_errors
  user_logins: dbtSnake.user_logins
  user_profiles_github: dbtSnake.user_profiles_github
  user_profiles_google: dbtSnake.user_profiles_google
  users: dbtSnake.users
  users_manual_deletions: dbtSnake.users_manual_deletions
}

export type DBTableNameSnake = Extract<keyof DBTablesSnake, string>

export const DBTableNamesSnake = [
  'addons',
  'app_quality_config',
  'app_quality_config_schemas',
  'assets_to_delete',
  'audit_addons',
  'audit_customers',
  'audit_organizations',
  'audit_plans',
  'audit_subscription_addons',
  'audit_subscriptions',
  'bigint_cloud_assets',
  'bigint_instance_details',
  'bigint_instance_stdout',
  'bigint_instances',
  'bigint_protocol_instance_raw',
  'bigint_protocol_test_data',
  'bigint_screencasts',
  'bigint_screenshots',
  'bigint_test_result_analytics',
  'bigint_test_result_attempt_errors',
  'bigint_test_result_attempts',
  'bigint_test_result_errors',
  'bigint_test_results_new',
  'bitbucket_app_install_organization',
  'bitbucket_app_pull_request_comment',
  'bitbucket_app_repository_project',
  'build_settings',
  'builds',
  'builds_tags',
  'capture_protocol_scripts',
  'capture_protocol_scripts_live_versions',
  'ci_keys',
  'ci_providers',
  'clickhouse_failed_insert',
  'cloud_assets',
  'customers',
  'deleted_projects',
  'deletions',
  'deletions_archive',
  'discovery_compaction_instance_results',
  'discovery_compaction_run_results',
  'enterprise_reporting',
  'feature_settings',
  'github_app_install',
  'github_app_install_enterprise',
  'github_app_install_organization',
  'github_app_pull_request_comment',
  'github_app_repository',
  'github_app_repository_owner',
  'github_app_status_check',
  'github_app_webhook_events',
  'gitlab_app_install_organization',
  'gitlab_app_merge_request_comment',
  'gitlab_app_repository',
  'identity_provider_config',
  'identity_provider_domain_mappings',
  'instance_actions',
  'instance_details',
  'instance_spec_hashes',
  'instance_stdout',
  'instances',
  'integrations',
  'jira_app_created_issues',
  'jira_app_install_organization',
  'jira_app_projects',
  'job_responsibilities',
  'machines',
  'membership_invitation_links',
  'membership_invitations',
  'membership_requests',
  'memberships',
  'microsoft_teams_app_installs',
  'next_estimated_spec_durations',
  'organization_onboarding_checklist',
  'organizations',
  'oss_plan_requests',
  'outgoing_emails',
  'plans',
  'project_branch_builds',
  'project_branches',
  'project_ci_providers',
  'project_committer_builds',
  'project_committers',
  'project_github_integration',
  'project_gitlab_integration',
  'project_intelligence_config',
  'project_transfers',
  'projects',
  'projects_to_delete',
  'protocol_hash_data',
  'protocol_instance_raw',
  'protocol_test_data',
  'run_composite_config',
  'run_groups',
  'run_groups_backfill',
  's3_upload_errors',
  'scheduled_tasks',
  'screencasts',
  'screenshots',
  'session',
  'slack_app_install_overrides',
  'slack_app_install_tag_overrides',
  'slack_app_installs',
  'slack_app_tokens',
  'sorry_cypress_limiter',
  'subscription_addons',
  'subscriptions',
  'tags',
  'terms_acceptances',
  'test_attribution',
  'test_attribution_unique',
  'test_orchestration',
  'test_result_actions',
  'test_result_analytics',
  'test_result_attempt_errors',
  'test_result_attempts',
  'test_result_body_hashes',
  'test_result_config_hashes',
  'test_result_errors',
  'test_result_hooks_hashes',
  'test_result_score_tracker_by_branch',
  'test_result_title_hashes',
  'test_results_new',
  'usage_limited_builds',
  'user_auth',
  'user_emails',
  'user_job_responsibilities',
  'user_keys',
  'user_login_errors',
  'user_logins',
  'user_profiles_github',
  'user_profiles_google',
  'users',
  'users_manual_deletions',
] as const
